import { AxiosError } from "axios";
import { Country, ICountry, State } from "country-state-city";
import { IDENTITY_TYPES, IIDENTITY } from "../types";

export const formatCurrency = (value: string | any): string | any => {
  if (value !== undefined) {
    // Remove non-numeric characters and leading zeros
    const sanitizedValue = value
      ?.replace(/[^\d.-]/g, "")
      ?.replace(/^0+/, "")
      ?.replace(/--+/g, "");

    // Handle negative numbers
    const isNegative = sanitizedValue.charAt(0) === "-";
    const sign = isNegative ? "-" : "";
    const absoluteValue = isNegative ? sanitizedValue.slice(1) : sanitizedValue;

    // Split into integer and decimal parts
    const [integerPart, decimalPart] = absoluteValue.split(".");

    // Add commas to the integer part
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Concatenate the formatted parts
    let formattedValue = `${sign}${formattedInteger}`;

    // Add decimal part if it exists
    if (decimalPart) {
      formattedValue += `.${decimalPart}`;
    }

    return formattedValue;
  }
};

export const convertStringToNumber = (str: any) => {
  if (str) {
    var cleanedStr = str.replace(/,/g, "");
    // Parse the cleaned string to an integer
    var number = parseInt(cleanedStr, 10);
    return number;
  }
};

export const COUNTRY_JSON: any = Country.getAllCountries().map(
  (i: ICountry) => {
    return { value: i?.isoCode, label: i?.name, isoCode: i?.isoCode };
  }
);

export const STATE_BY_COUNTRYCODE = (stateCode: string) => {
  if (stateCode) {
    const result = State.getStatesOfCountry(stateCode);
    const stateJson = result.map((i: any) => {
      return { label: i?.name, value: i?.name };
    });
    return stateJson;
  } else {
    return [];
  }
};

export const phoneRegExp =
  /^((\\+[1-11]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const alphaBetRegExp = /^[A-Za-z]+$/;

export const getLocalStorageData = (key: string, initialValue: any) => {
  const storedData = localStorage.getItem(key);
  return storedData ? JSON.parse(storedData) : initialValue;
};

export const hasEmptyStringValues = <T extends object>(obj: T): boolean => {
  return Object.values(obj).some((value) => value === "");
};

export const filterCountriesByISO3 = (countries: any, iso3: string): any => {
  if (countries && iso3) {
    const result = countries.filter((country: any) => country.value === iso3);
    return result;
  }
};

export const formatString = (input: string | any): string | any => {
  if (input) {
    return input
      .toLowerCase()
      .split("_")
      .map((word: string | any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
};

export const isSerializable = (obj: any): boolean => {
  try {
    JSON.stringify(obj);
    return true;
  } catch (e) {
    return false;
  }
};

export const unAuthorizedhandler = (error: AxiosError) => {
  return error;
};

type Path = string;

export const extractAndFormatPathSegment = (path: Path): string => {
  const parts: string[] = path.split("/");
  const lastSegment: string = parts[parts.length - 1];
  const formattedSegment: string = lastSegment
    .split("_")
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return formattedSegment;
};

export const verifyObject = (obj: any): boolean => {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      return false;
    }
    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      if (!verifyObject(obj[key])) {
        return false;
      }
    }
  }
  return true;
};

export const filterIdentity = (
  identityArray: IIDENTITY[],
  filterValue: IDENTITY_TYPES | string
) => identityArray.filter((identity) => identity.value === filterValue);

export const filterIndustry = (
  industryArray: { label: string; value: string }[],
  filterValue: string
) => industryArray.filter((i) => i.value === filterValue);

export const filterStatus = (
  statusArray: { label: string; value: string }[],
  filterValue: string
) => statusArray.filter((i) => i.value === filterValue);

export const isURL = (str: string): boolean => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-zA-Z0-9]{1,256}\\.[a-zA-Z0-9]{1,6})|" + // domain name
      "localhost|" + // localhost
      "(([0-9]{1,3}\\.){3}[0-9]{1,3}))" + // OR ipv4
      "(\\:[0-9]{1,5})?)" + // port
      "(\\/[-a-zA-Z0-9@:%._\\+~#=]{1,256})*" + // path
      "(\\?[;&a-zA-Z0-9@:%_\\+.~#?&//=]*)?" + // query string
      "(\\#[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const toTwoDecimalPlaces = (str: string) => {
  const num = parseFloat(str);
  const mathedNumber = Math.round(num * 100) / 100;
  return mathedNumber.toFixed(2);
};

export const getFormattedDate = (): string => {
  const today: Date = new Date();
  const dd: string = String(today.getDate()).padStart(2, "0");
  const mm: string = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy: number = today.getFullYear();

  return `${dd}-${mm}-${yyyy}`;
};

export const extractCountryCodes = (messages: string[]): string => {
  return messages.join(", ");
};
